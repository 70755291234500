import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import APIGen, {
  NodeV2ResponseModel,
  NodeParentInformationResponseModel,
  NodeSearchResultsResponseModel,
  PropertyValidationType,
  NodePropertySearchRequestModel,
  NodeTraitNodeRelationResponseModel
} from 'ecto-common/lib/API/APIGen';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';
import styles from './NodeSearchTable.module.css';
import DataTableLoadMoreFooter from 'ecto-common/lib/DataTable/DataTableLoadMoreFooter';
import SearchInput, {
  SearchInputWithMenu
} from 'ecto-common/lib/SearchInput/SearchInput';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import { getSelectedLanguage } from 'ecto-common/lib/utils/localStorageUtil';
import { useNodeTraits } from 'ecto-common/lib/hooks/useCurrentNode';

type NodeV2ResponseModelWithParent = NodeV2ResponseModel & {
  parent: NodeParentInformationResponseModel;
};

export const NodeSearchTablePropertyDataKey = 'property-search';
export const NodeSearchTableTraitsKey = 'traits';

export const clearNodeSearchTableURLState = (params: URLSearchParams) => {
  params.delete(NodeSearchTablePropertyDataKey);
  params.delete(NodeSearchTableTraitsKey);
};

const NodeSearchTable = ({
  onClickNode
}: {
  onClickNode: (node: NodeV2ResponseModel) => void;
}) => {
  const traitsQuery = useNodeTraits();
  const propertiesQuery = APIGen.NodesV2.listNodeProperties.useQuery();
  const language = getSelectedLanguage();

  const propertyOptions = useMemo(() => {
    return _.map(propertiesQuery.data?.items, (property) => {
      return {
        label: property.localization?.[language] ?? property.name,
        value: property.id
      };
    });
  }, [language, propertiesQuery.data]);

  const options = useMemo(() => {
    return _.map(
      sortByLocaleCompare(traitsQuery.data?.items, 'name'),
      (trait) => ({
        value: trait.id,
        label: trait.name
      })
    );
  }, [traitsQuery.data]);

  const [selectedTraitIdsString, setSelectedTraitIds] = useSearchParamState(
    NodeSearchTableTraitsKey,
    null
  );

  const selectedTraitIds = useMemo(() => {
    if (selectedTraitIdsString == null) {
      return [];
    }

    return selectedTraitIdsString.split(',');
  }, [selectedTraitIdsString]);

  const [propertySearchData, setPropertySearchData] = useSearchParamState(
    NodeSearchTablePropertyDataKey,
    ''
  );
  const selectedTraitOptions = useMemo(() => {
    return options.filter((option) => selectedTraitIds.includes(option.value));
  }, [options, selectedTraitIds]);

  const [activePropertyIndex, setActivePropertyIndex] = useState(-1);

  const [searchPhrase, setSearchPhrase] = useState('');

  const [propertySuggestionPhrase, setPropertySuggestionPhrase] =
    useState(propertySearchData);

  const propertyModels = useMemo((): NodePropertySearchRequestModel[] => {
    if (propertySearchData == null) {
      return [];
    }

    const elems = propertySearchData.split(',');
    if (elems.length % 2 !== 0) {
      return [];
    }

    return _.compact(
      _.map(_.chunk(elems, 2), ([propertyId, itemSearchPhrase]) => {
        const nodePropertyId =
          propertyId === 'null' || propertyId === 'undefined'
            ? null
            : propertyId;

        const property = propertiesQuery.data?.items?.find(
          (x) => x.id === nodePropertyId
        );
        return {
          nodePropertyId,
          searchPhrase: itemSearchPhrase,
          exactMatch:
            property?.validationType === PropertyValidationType.EnumList
        };
      })
    );
  }, [propertiesQuery.data, propertySearchData]);

  const setPropertyModels = useCallback(
    (newData: NodePropertySearchRequestModel[]) => {
      const newString = _.map(newData, (model) => {
        return `${model.nodePropertyId},${model.searchPhrase}`;
      }).join(',');

      setPropertySearchData(newString);
    },
    [setPropertySearchData]
  );

  const validPropertyModels = useMemo(() => {
    return _.filter(propertyModels, (model) => {
      return !isNullOrWhitespace(model.nodePropertyId);
    });
  }, [propertyModels]);

  const nodesQuery = APIGen.NodesV2.searchForNodes.useInfiniteQuery(
    {
      nodeTraitIds: selectedTraitIds,
      nodePropertySearchRequestModels: validPropertyModels,
      searchPhrase,
      pageSize: 20
    },
    {
      enabled: propertiesQuery.data != null
    }
  );

  const nodesResult = useMemo(() => {
    const combinedResult: Omit<
      NodeSearchResultsResponseModel,
      'continuationToken'
    > = {
      nodes: [],
      parents: [],
      propertiesAndValues: []
    };

    for (const page of nodesQuery.data?.pages ?? []) {
      combinedResult.nodes.push(...page.nodes);
      combinedResult.parents.push(...page.parents);
      combinedResult.propertiesAndValues.push(...page.propertiesAndValues);
    }

    return combinedResult;
  }, [nodesQuery.data?.pages]);

  const nodePropertiesQuery = APIGen.NodesV2.listNodeProperties.useQuery({});
  const activePropertyId = propertyModels[activePropertyIndex]?.nodePropertyId;

  const selectedProperty = nodePropertiesQuery.data?.items?.find(
    (x) => x.id === activePropertyId
  );

  const nodeRootPropertySuggestionsQuery =
    APIGen.NodesV2.getNodeValueSuggestions.useQuery(
      {
        nodePropertyId: activePropertyId,
        searchPhrase: null,
        numSuggestions: 15
      },
      {
        enabled:
          !!activePropertyId &&
          selectedProperty?.validationType !== PropertyValidationType.EnumList
      }
    );

  const nodePropertySuggestionsQuery =
    APIGen.NodesV2.getNodeValueSuggestions.useQuery(
      {
        nodePropertyId: activePropertyId,
        searchPhrase: propertySuggestionPhrase,
        numSuggestions: 15
      },
      {
        enabled:
          !!activePropertyId &&
          selectedProperty?.validationType !==
            PropertyValidationType.EnumList &&
          !nodeRootPropertySuggestionsQuery.isLoading &&
          nodeRootPropertySuggestionsQuery?.data.hasMore
      }
    );

  const columns = useMemo<
    DataTableColumnProps<NodeV2ResponseModelWithParent>[]
  >(() => {
    return _.compact([
      {
        dataKey: 'name',
        label: T.common.name,
        linkColumn: true
      },

      ...validPropertyModels.map((propertyModel) => {
        const property = propertiesQuery.data?.items?.find(
          (x) => x.id === propertyModel.nodePropertyId
        );

        if (property == null) {
          return null;
        }

        return {
          dataKey: 'nodeId',
          label: property.localization?.[language] ?? property.name,
          dataFormatter: (nodeId: string) => {
            const propertyValue = nodesResult.propertiesAndValues.find(
              (x) =>
                x.nodeId === nodeId &&
                x.nodePropertyId === propertyModel.nodePropertyId
            );

            return propertyValue?.value;
          }
        };
      }),
      {
        dataKey: 'parent.name',
        label: T.nodes.parent
      },
      {
        dataKey: 'nodeTraits',
        label: T.nodes.nodetraits,
        dataFormatter: (
          traitRelations: NodeTraitNodeRelationResponseModel[]
        ) => {
          const traits = sortByLocaleCompare(
            _.compact(
              _.map(traitRelations, ({ nodeTraitId }) => {
                return _.find(traitsQuery.data?.items, { id: nodeTraitId });
              })
            ),
            'name'
          );

          return (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                gap: 1
              }}
            >
              <TagsGroup tags={_.orderBy(traits, 'name').map((t) => t.name)} />
            </div>
          );
        }
      }
    ]);
  }, [
    validPropertyModels,
    propertiesQuery.data,
    nodesResult.propertiesAndValues,
    traitsQuery.data,
    language
  ]);

  const onClickRow = (node: NodeV2ResponseModel) => {
    onClickNode(node);
  };

  const searchTableData: NodeV2ResponseModelWithParent[] = useMemo(() => {
    return _.map(nodesResult.nodes, (node) => {
      return {
        ...node,
        parent: nodesResult.parents.find(
          (parent) => parent.nodeId === node.parentId
        )
      };
    });
  }, [nodesResult.nodes, nodesResult.parents]);

  const searchPhraseOptions = useMemo(() => {
    if (selectedProperty?.validationType === PropertyValidationType.EnumList) {
      return _.map(selectedProperty?.validationData?.enumValues, (value) => ({
        label: value,
        value
      }));
    }

    let collection = nodePropertySuggestionsQuery.data?.suggestions;

    if (!nodeRootPropertySuggestionsQuery.data?.hasMore) {
      collection = nodeRootPropertySuggestionsQuery.data?.suggestions;
    }

    return _.map(collection, (value) => ({
      label: value,
      value
    }));
  }, [
    nodePropertySuggestionsQuery.data?.suggestions,
    nodeRootPropertySuggestionsQuery.data?.hasMore,
    nodeRootPropertySuggestionsQuery.data?.suggestions,
    selectedProperty?.validationData?.enumValues,
    selectedProperty?.validationType
  ]);

  const [propertyOptionsIndex, setPropertyOptionsIndex] = useState(-1);

  if (propertyOptionsIndex > searchPhraseOptions.length - 1) {
    setPropertyOptionsIndex(searchPhraseOptions.length - 1);
  }

  return (
    <>
      <KeyValueLine>
        <KeyValueGeneric keyText={T.nodes.search.searchnodes}>
          <SearchInput
            placeholder={T.nodes.search.searchnodes}
            value={searchPhrase}
            onChange={(value) => {
              setSearchPhrase(value);
            }}
          />
        </KeyValueGeneric>
      </KeyValueLine>
      <KeyValueLine>
        <KeyValueGeneric keyText={T.nodes.search.filternodetraits}>
          <Select<GenericSelectOption<string>, true>
            options={options}
            isMulti
            onChange={(data: GenericSelectOption<string>[]) => {
              setSelectedTraitIds(data.map((x) => x.value).join(','));
            }}
            value={selectedTraitOptions}
          />
        </KeyValueGeneric>
      </KeyValueLine>

      <KeyValueGeneric keyText={T.nodes.search.filternodeproperty}>
        {propertyModels.map((propertyModel, index) => {
          return (
            <KeyValueLine key={index}>
              <Select<GenericSelectOption<string>, false>
                options={propertyOptions}
                placeholder={T.nodes.search.selectnodeproperty}
                onChange={(data: GenericSelectOption<string>) => {
                  const newModels = [...propertyModels];
                  newModels[index] = {
                    ...newModels[index],
                    nodePropertyId: data?.value,
                    searchPhrase: ''
                  };
                  setPropertyModels(newModels);
                  setPropertySuggestionPhrase(null);
                }}
                className={styles.expand}
                value={propertyOptions.find(
                  (propertyOption) =>
                    propertyOption.value === propertyModel.nodePropertyId
                )}
                isClearable
              />
              <SearchInputWithMenu
                wrapperClassName={styles.expand}
                disabled={propertyModel.nodePropertyId == null}
                placeholder={T.nodes.search.searchpropertyvalue}
                highlightText={propertySuggestionPhrase}
                onSelectMenuOption={(option) => {
                  setPropertySuggestionPhrase(option.value);

                  const newModels = [...propertyModels];
                  newModels[index] = {
                    ...newModels[index],
                    searchPhrase: option.value
                  };

                  setPropertyModels(newModels);
                }}
                clearButton
                onClear={() => {
                  setPropertySuggestionPhrase(null);
                  const newModels = [...propertyModels];
                  newModels[index] = {
                    ...newModels[index],
                    searchPhrase: ''
                  };

                  setPropertyModels(newModels);
                }}
                onArrowDownKeyPressed={() => {
                  setPropertyOptionsIndex((oldIndex) => {
                    let newIndex = oldIndex + 1;
                    if (newIndex >= searchPhraseOptions.length) {
                      newIndex = 0;
                    }
                    return Math.min(newIndex, searchPhraseOptions.length - 1);
                  });
                }}
                onArrowUpKeyPressed={() => {
                  setPropertyOptionsIndex((oldIndex) => {
                    let newIndex = oldIndex - 1;
                    if (newIndex < 0) {
                      newIndex = searchPhraseOptions.length - 1;
                    }
                    return newIndex;
                  });
                }}
                onEnterKeyPressed={() => {
                  let newSearchPhrase = propertySuggestionPhrase;
                  if (propertyOptionsIndex >= 0) {
                    setPropertySuggestionPhrase(
                      searchPhraseOptions[propertyOptionsIndex].value
                    );

                    newSearchPhrase =
                      searchPhraseOptions[propertyOptionsIndex].value;
                  }

                  const newModels = [...propertyModels];
                  newModels[index] = {
                    ...newModels[index],
                    searchPhrase: newSearchPhrase
                  };

                  setPropertyModels(newModels);
                }}
                onFocus={() => {
                  setActivePropertyIndex(index);
                }}
                onFocusLost={() => {
                  setPropertyOptionsIndex(-1);
                  setActivePropertyIndex(-1);
                }}
                initialValue={propertyModel.searchPhrase}
                selectedIndex={propertyOptionsIndex}
                showMenu={
                  searchPhraseOptions.length > 0 &&
                  activePropertyIndex === index
                }
                onChange={(value) => {
                  setPropertySuggestionPhrase(value);
                }}
                menuOptions={searchPhraseOptions}
              />
              <DeleteButton
                isIconButton
                onClick={() => {
                  const newModels = [...propertyModels];
                  newModels.splice(index, 1);
                  setPropertyModels(newModels);
                }}
              />
            </KeyValueLine>
          );
        })}
        <div className={styles.propertySearchButtons}>
          <Button
            onClick={() =>
              setPropertyModels([
                ...propertyModels,
                {
                  nodePropertyId: null,
                  searchPhrase: '',
                  exactMatch: false
                }
              ])
            }
          >
            <Icons.Add />
            {T.nodes.search.addproperty}
          </Button>
        </div>
      </KeyValueGeneric>

      <DataTable
        columns={columns}
        onClickRow={onClickRow}
        data={searchTableData}
        isLoading={nodesQuery.isLoading}
      />
      <DataTableLoadMoreFooter
        isFetchingNextPage={nodesQuery.isFetchingNextPage}
        hasNextPage={nodesQuery.hasNextPage}
        fetchNextPage={nodesQuery.fetchNextPage}
      />
    </>
  );
};

export default React.memo(NodeSearchTable);
