import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import APIGen, { NodeTraitResponseModel } from 'ecto-common/lib/API/APIGen';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AdminPage from 'js/components/AdminPage';
import {
  BatchedGetNodesQueryKey,
  useNodeTraits
} from 'ecto-common/lib/hooks/useCurrentNode';
import CRUDView, {
  CRUDViewModelEnvironment,
  useSimpleCrudViewData
} from 'ecto-common/lib/CRUDView/CRUDView';
import CheckMark from 'ecto-common/lib/Icon/svg/CheckMark';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { getSelectedLanguage } from 'ecto-common/lib/utils/localStorageUtil';
import slugify from 'slugify';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';

const NodeTraitsList = () => {
  const traitsQuery = useNodeTraits();

  const columns = useMemo<
    DataTableColumnProps<NodeTraitResponseModel>[]
  >(() => {
    return [
      {
        dataKey: 'isGlobal',
        label: T.nodes.global,
        minWidth: 70,
        maxWidth: 70,
        align: 'center',
        dataFormatter: (value: boolean) => (value ? <CheckMark /> : null)
      },
      {
        dataKey: 'isLocked',
        label: T.nodes.locked,
        minWidth: 80,
        maxWidth: 80,
        align: 'center',
        dataFormatter: (value) => (value ? <Icons.Lock /> : null)
      },
      {
        dataKey: 'name',
        label: T.common.name,
        linkColumn: true
      },
      {
        dataKey: 'id',
        label: T.common.id,
        dataFormatter: (value: string) => {
          return (
            <CopyToClipboardTooltip valueToCopy={value}>
              {value}
            </CopyToClipboardTooltip>
          );
        }
      }
    ];
  }, []);
  const propertiesQuery = APIGen.NodesV2.listNodeProperties.useQuery();

  const language = getSelectedLanguage();

  const propertyOptions = useMemo(() => {
    return sortByLocaleCompare(
      _.map(propertiesQuery.data?.items, (property) => {
        return {
          label: property.localization?.[language] ?? property.name,
          value: property.id
        };
      }),
      'label'
    );
  }, [propertiesQuery.data, language]);

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: APIGen.NodesV2.listNodeTraits.path(contextSettings)
    });

    queryClient.invalidateQueries({
      queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
    });
    queryClient.invalidateQueries({
      queryKey: [BatchedGetNodesQueryKey]
    });
  }, [contextSettings, queryClient]);

  const traitModels: ModelDefinition<
    NodeTraitResponseModel,
    CRUDViewModelEnvironment
  >[] = useMemo(
    () => [
      {
        key: (input) => input.name,
        label: T.common.name,
        modelType: ModelType.TEXT,
        enabled: (input) => !input.isLocked,
        hasError: isNullOrWhitespace,
        onDidUpdate: (_path, value, _object, env) => {
          if (env.isNew) {
            const sluggedName = slugify(value, { lower: true });

            return [[(input) => input.id, sluggedName]];
          }
        }
      },
      {
        key: (input) => input.id,
        label: T.common.id,
        modelType: ModelType.TEXT,
        enabled: false,
        hasError: isNullOrWhitespace
      },
      {
        key: (input) => input.propertyIds,
        label: T.nodes.nodeproperties,
        modelType: ModelType.OPTIONS,
        isMultiOption: true,
        options: propertyOptions,
        isHorizontal: true
      }
    ],
    [propertyOptions]
  );

  const crudData = useSimpleCrudViewData({
    listQuery: traitsQuery,
    searchItems: ['name'],
    sortBy: 'name'
  });

  const createItemMutation = useMutation({
    mutationFn: (item: NodeTraitResponseModel) =>
      APIGen.NodesV2.addOrUpdateNodeTraits.promise(
        contextSettings,
        { nodeTraits: [item] },
        null
      )
  });

  const updateItemMutation = useMutation({
    mutationFn: (item: NodeTraitResponseModel) =>
      APIGen.NodesV2.addOrUpdateNodeTraits.promise(
        contextSettings,
        { nodeTraits: [item] },
        null
      )
  });

  const deleteItemMutation = useMutation({
    mutationFn: (item: NodeTraitResponseModel) =>
      APIGen.NodesV2.deleteNodeTraits.promise(
        contextSettings,
        { nodeTraitIds: [item.id] },
        null
      )
  });

  const createNewItem = (): NodeTraitResponseModel => ({
    id: 'new-trait',
    name: 'New trait',
    isGlobal: false,
    isLocked: false,
    propertyIds: []
  });

  return (
    <CRUDView
      columns={columns}
      createNewItem={createNewItem}
      itemName={'name'}
      title={T.nodes.nodetraits}
      editTitle={T.traits.edittrait}
      addTitle={T.traits.addtrait}
      deleteItemMutation={deleteItemMutation}
      updateItemMutation={updateItemMutation}
      createItemMutation={createItemMutation}
      models={traitModels}
      onAdded={invalidateCache}
      onUpdated={invalidateCache}
      {...crudData}
    />
  );
};

const NodeTraits = () => {
  useEffect(() => {
    document.title = T.nodes.nodetraits;
  }, []);

  return <AdminPage content={<NodeTraitsList />} />;
};

export default React.memo(NodeTraits);
