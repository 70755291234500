import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  ROOT_NODE_ID
} from 'ecto-common/lib/constants';

import TextInput from 'ecto-common/lib/TextInput/TextInput';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import DraggableMarkerMap from 'ecto-common/lib/Map/DraggableMarkerMap';

import styles from 'js/components/EditLocation/LocationForm.module.css';
import APIGen, {
  NodePropertyResponseModel,
  NodeTraitNodeRelationResponseModel,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';

import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { usePresentationItemQuery } from 'ecto-common/lib/utils/presentationLibrary';
import PresentationAPIGen from 'ecto-common/lib/API/PresentationAPIGen';
import {
  nodeIsBuilding,
  nodeIsEquipment,
  useNodeTraits
} from 'ecto-common/lib/hooks/useCurrentNode';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import MapMarker from 'ecto-common/lib/Map/MapMarker';
import Map from 'ecto-common/lib/Map/Map';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { keyValueFixedSelectableMenuComponents } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import { StylesConfig } from 'react-select';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';
import SelectNodeDialog from 'ecto-common/lib/SelectNodeDialog/SelectNodeDialog';
import { PropertyValidationType } from '../../../../../ecto-common/lib/API/APIGen';
import KeyValueInternalSelectableInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalSelectableInput';
import dimensions from 'ecto-common/lib/styles/dimensions';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import SaveButton from 'ecto-common/lib/Button/SaveButton';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { getSelectedLanguage } from 'ecto-common/lib/utils/localStorageUtil';

const createInfoItem = (title: string, content: React.ReactNode) => ({
  title,
  content
});

export type LocationFormData = {
  parentId: string;
  name: string;
  description?: string;
  street: string;
  nodeTraits: NodeTraitNodeRelationResponseModel[];
  latitude: number;
  longitude: number;
};

interface LocationFormProps {
  selectedLocation?: NodeV2ResponseModel;
  formData: LocationFormData;
  onFormDataChanged(newData: Partial<LocationFormData>): void;
  onEditDashboards(): void;
  onEditProcessMaps(): void;
  onEditNotifications(): void;
  onEditFiles(): void;
  onSaveNode(): void;
  onDeleteNode(): void;
  saveButtonDisabled: boolean;
  isVirtualRootNode: boolean;
  nodeProperties: NodePropertyResponseModel[];
  setNodePropertyValues: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  nodePropertyValues: Record<string, string>;
  isLoadingNodePropertyValues: boolean;
  propertyErrors: Record<string, boolean>;
}

const fixedSelectStyles: StylesConfig<GenericSelectOption, true> = {
  multiValueRemove: (base) => {
    return { ...base, display: 'none' };
  }
};

const FixedSelectInfo = ({
  options,
  onClick,
  placeholder
}: {
  options: GenericSelectOption[];
  onClick: () => void;
  placeholder?: string;
}) => {
  return (
    <div>
      <Select<GenericSelectOption, true>
        className={styles.fixedInput}
        options={options}
        value={options}
        components={keyValueFixedSelectableMenuComponents}
        onMenuOpen={onClick}
        isClearable={false}
        isMulti
        isSearchable={false}
        styles={fixedSelectStyles}
        placeholder={placeholder ?? T.admin.editlocation.fixedselectplaceholder}
      />
    </div>
  );
};

const NotificationInfo = ({
  selectedLocation,
  onEditNotifications
}: {
  selectedLocation: NodeV2ResponseModel;
  onEditNotifications: () => void;
}) => {
  const notificationsQuery =
    APIGen.AdminNotifications.getAlarmNotificationsByNodeIds.useQuery({
      NodeIds: [selectedLocation.nodeId]
    });

  const numNotifications =
    notificationsQuery.data?.alarmNotifications.length ?? 0;

  const options: GenericSelectOption[] = useMemo(() => {
    if (numNotifications === 0) {
      return [];
    }

    return [
      {
        label: T.format(
          T.admin.editlocation.selectformats.notifications,
          numNotifications
        ),
        value: null as string
      }
    ];
  }, [numNotifications]);

  return <FixedSelectInfo options={options} onClick={onEditNotifications} />;
};

const DashboardInfo = ({
  selectedLocation,
  onEditDashboards
}: {
  selectedLocation: NodeV2ResponseModel;
  onEditDashboards: () => void;
}) => {
  const { query: relationQuery } = usePresentationItemQuery({
    queryHook: PresentationAPIGen.Nodes.getDashboards.useQuery,
    nodeId: selectedLocation.nodeId,
    equipmentTypeId: null
  });

  const options = useMemo(() => {
    return _.get(relationQuery.data, 'items', []).map((item) => {
      return {
        label: item.name,
        value: item.id
      };
    });
  }, [relationQuery.data]);

  return <FixedSelectInfo options={options} onClick={onEditDashboards} />;
};

function FilesInfo({
  nodeId,
  onClick
}: {
  nodeId: string;
  onClick: () => void;
}) {
  const listQuery = PresentationAPIGen.Nodes.getFiles.useQuery(
    { nodeId },
    null,
    {
      enabled: !_.isEmpty(nodeId)
    }
  );

  const numFiles = listQuery.data?.items?.length ?? 0;

  const options: GenericSelectOption[] = useMemo(() => {
    if (numFiles === 0) {
      return [];
    }

    return [
      {
        label: T.format(T.admin.editlocation.selectformats.files, numFiles),
        value: null as string
      }
    ];
  }, [numFiles]);

  return (
    <FixedSelectInfo
      options={options}
      onClick={onClick}
      placeholder={T.admin.editlocation.fields.filesplaceholder}
    />
  );
}

const ProcessMapInfo = ({
  selectedLocation,
  onEditProcessMaps
}: {
  selectedLocation: NodeV2ResponseModel;
  onEditProcessMaps: () => void;
}) => {
  const { query: relationQuery } = usePresentationItemQuery({
    queryHook: PresentationAPIGen.Nodes.getPictures.useQuery,
    nodeId: selectedLocation.nodeId,
    equipmentTypeId: null
  });

  const options = useMemo(() => {
    return _.get(relationQuery.data, 'items', []).map((item) => {
      return {
        label: item.name,
        value: item.id
      };
    });
  }, [relationQuery.data]);

  return <FixedSelectInfo options={options} onClick={onEditProcessMaps} />;
};

const MapInfo = ({
  latitude,
  longitude,
  onClick
}: {
  latitude: number;
  longitude: number;
  onClick: () => void;
}) => {
  const center = [latitude, longitude];
  const language = useAdminSelector((state) => state.general.language);
  const mapOptions = {
    fullscreenControl: false
  };

  if (latitude == null || longitude == null) {
    return null;
  }

  return (
    <div className={styles.inlineMap}>
      <div className={styles.inlineMapOverlay} onClick={onClick} />

      <Map zoom={10} center={center} options={mapOptions} language={language}>
        <MapMarker lat={center[0]} lng={center[1]} />
      </Map>
    </div>
  );
};

type TraitOption = {
  value: string;
  label: string;
  isLocked: boolean;
};

const selectStyles: StylesConfig<TraitOption, true> = {
  multiValueLabel: (base, state) => {
    return state.data.isLocked ? { ...base, paddingRight: 6 } : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isLocked ? { ...base, display: 'none' } : base;
  }
};

const NodeTraitsInfo = ({
  nodeTraits,
  setNodeTraits
}: {
  nodeTraits: NodeTraitNodeRelationResponseModel[];
  setNodeTraits: (
    newNodeTraitIds: NodeTraitNodeRelationResponseModel[]
  ) => void;
}) => {
  const nodeTraitsQuery = useNodeTraits();

  const options: TraitOption[] = useMemo(() => {
    return sortByLocaleCompare(
      _.map(nodeTraitsQuery.data?.items, (nodeTrait) => {
        return {
          value: nodeTrait.id,
          label: nodeTrait.name,
          isLocked: nodeTraits.some(
            (x) => x.nodeTraitId === nodeTrait.id && x.isLocked
          )
        };
      }),
      'label'
    );
  }, [nodeTraits, nodeTraitsQuery.data?.items]);

  const selectedOptions = useMemo(() => {
    return options.filter((x) =>
      nodeTraits.some((trait) => trait.nodeTraitId === x.value)
    );
  }, [nodeTraits, options]);

  return (
    <Select<TraitOption, true>
      isMulti
      options={options}
      value={selectedOptions}
      styles={selectStyles}
      onChange={(changedOptions) => {
        const newValues: NodeTraitNodeRelationResponseModel[] = changedOptions
          .filter(
            (option) =>
              !nodeTraits.some((trait) => trait.nodeTraitId === option.value)
          )
          .map((x) => ({ nodeTraitId: x.value, isLocked: false }));
        const oldValues = nodeTraits.filter((trait) =>
          changedOptions.some((option) => option.value === trait.nodeTraitId)
        );
        setNodeTraits([...oldValues, ...newValues]);
      }}
    />
  );
};

const EditParentInput = ({
  parentId,
  nodeId,
  onParentIdChanged
}: {
  parentId: string;
  nodeId: string;
  onParentIdChanged: (newParentId: string) => void;
}) => {
  const parentNode = useNode(parentId);
  const [dialogIsOpen, showDialog, hideDialog] = useSimpleDialogState();

  const options = useMemo(() => {
    return parentNode.node
      ? [{ value: parentId, label: parentNode.node.name }]
      : [];
  }, [parentId, parentNode]);

  const onNodesSelected = useCallback(
    (nodeIds: string[]) => {
      if (nodeIds.length === 0) {
        onParentIdChanged(null);
      }
      onParentIdChanged(nodeIds[0]);
      hideDialog();
    },
    [hideDialog, onParentIdChanged]
  );

  const filterNodes = useCallback(
    (nodes: NodeV2ResponseModel[]) => {
      const filtered = nodes.filter((node) => {
        return !node.path.includes(nodeId);
      });

      return filtered;
    },
    [nodeId]
  );

  return (
    <>
      <FixedSelectInfo options={options} onClick={showDialog} />
      <SelectNodeDialog
        isOpen={dialogIsOpen}
        onModalClose={hideDialog}
        nodeIds={parentId == null ? [] : [parentId]}
        onNodesSelected={onNodesSelected}
        multiSelect={false}
        filterNodes={filterNodes}
      />
    </>
  );
};

const LocationForm = ({
  selectedLocation,
  onEditNotifications,
  formData,
  onFormDataChanged,
  onEditDashboards,
  onEditProcessMaps,
  onEditFiles,
  isVirtualRootNode,
  nodeProperties,
  setNodePropertyValues,
  nodePropertyValues,
  isLoadingNodePropertyValues,
  propertyErrors,
  onSaveNode,
  onDeleteNode,
  saveButtonDisabled
}: LocationFormProps) => {
  const onUpdateName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFormDataChanged({ name: event.target.value });
    },
    [onFormDataChanged]
  );

  const onUpdateParentId = useCallback(
    (newParentId: string) => {
      onFormDataChanged({ parentId: newParentId });
    },
    [onFormDataChanged]
  );

  const onUpdateDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFormDataChanged({ description: event.target.value });
    },
    [onFormDataChanged]
  );

  const onCoordinateChanged = useCallback(
    (latitude: number, longitude: number) => {
      onFormDataChanged({ latitude, longitude });
    },
    [onFormDataChanged]
  );

  const isRootNode = selectedLocation.nodeId.startsWith(ROOT_NODE_ID);

  const [isShowingMap, showMap, hideMap] = useSimpleDialogState(false);

  const inputItems = useMemo(
    () =>
      _.compact([
        {
          title: T.admin.editlocation.fields.name,
          content: (
            <TextInput
              autoComplete="off"
              spellCheck="false"
              wrapperClassName={styles.inputItem}
              disabled={isRootNode}
              placeholder={T.admin.editlocation.placeholder.name}
              value={formData.name}
              onChange={onUpdateName}
            />
          )
        },
        {
          title: T.admin.editlocation.fields.description,
          content: (
            <TextInput
              autoComplete="off"
              spellCheck="false"
              wrapperClassName={styles.inputItem}
              disabled={isRootNode}
              placeholder={T.admin.editlocation.fields.description}
              value={formData.description}
              onChange={onUpdateDescription}
            />
          )
        },
        {
          title: T.admin.editlocation.fields.parent,
          content: (
            <EditParentInput
              nodeId={selectedLocation.nodeId}
              parentId={formData.parentId}
              onParentIdChanged={onUpdateParentId}
            />
          )
        },
        !nodeIsEquipment(selectedLocation) &&
          createInfoItem(
            T.admin.editlocation.fields.mapcoordinate,
            <MapInfo
              latitude={formData.latitude}
              longitude={formData.longitude}
              onClick={showMap}
            />
          )
      ]),
    [
      isRootNode,
      formData.name,
      formData.description,
      formData.parentId,
      formData.latitude,
      formData.longitude,
      onUpdateName,
      onUpdateDescription,
      onUpdateParentId,
      selectedLocation,
      showMap
    ]
  );

  const infoItems = useMemo(
    () =>
      location &&
      _.compact([
        createInfoItem(
          T.nodes.nodetraits,
          <NodeTraitsInfo
            nodeTraits={formData.nodeTraits}
            setNodeTraits={(newNodeTraits) => {
              onFormDataChanged({ nodeTraits: newNodeTraits });
            }}
          />
        ),
        createInfoItem(
          T.admin.editlocation.fields.processmaps,
          <ProcessMapInfo
            selectedLocation={selectedLocation}
            onEditProcessMaps={onEditProcessMaps}
          />
        ),
        createInfoItem(
          T.admin.editlocation.fields.dashboards,
          <DashboardInfo
            selectedLocation={selectedLocation}
            onEditDashboards={onEditDashboards}
          />
        ),
        createInfoItem(
          T.admin.editlocation.fields.files,
          <FilesInfo nodeId={selectedLocation.nodeId} onClick={onEditFiles} />
        ),
        createInfoItem(
          T.admin.editlocation.fields.notifications,
          <NotificationInfo
            selectedLocation={selectedLocation}
            onEditNotifications={onEditNotifications}
          />
        )
      ]),
    [
      formData.nodeTraits,
      onEditDashboards,
      onEditFiles,
      onEditNotifications,
      onEditProcessMaps,
      onFormDataChanged,
      selectedLocation
    ]
  );
  const language = getSelectedLanguage();

  const propertyItems = useMemo(
    () =>
      location &&
      _.compact([
        ...nodeProperties.map((property) => {
          const value = nodePropertyValues[property.id];

          if (property.validationType === PropertyValidationType.EnumList) {
            const options = property.validationData.enumValues.map(
              (enumValue) => ({
                label: enumValue,
                value: enumValue
              })
            );

            return {
              title: property.localization?.[language] ?? property.name,
              content: (
                <KeyValueInternalSelectableInput
                  options={options}
                  hasError={propertyErrors[property.id]}
                  value={{
                    label: value,
                    value
                  }}
                  onChange={(selectedOption) => {
                    setNodePropertyValues((oldValues) => {
                      return {
                        ...oldValues,
                        [property.id]: selectedOption.value
                      };
                    });
                  }}
                />
              )
            };
          }

          return {
            title: property.localization?.[language] ?? property.name,
            content: (
              <TextInput
                value={value}
                error={propertyErrors[property.id]}
                onChange={(e) => {
                  setNodePropertyValues((oldValues) => {
                    return {
                      ...oldValues,
                      [property.id]: e.target.value
                    };
                  });
                }}
              />
            )
          };
        })
      ]),
    [
      nodeProperties,
      nodePropertyValues,
      propertyErrors,
      setNodePropertyValues,
      language
    ]
  );

  const center = useMemo(
    () => ({
      lat: formData.latitude ?? DEFAULT_LAT,
      lng: formData.longitude ?? DEFAULT_LNG
    }),
    [formData.latitude, formData.longitude]
  );

  return (
    <>
      <form autoComplete="off">
        <div className={styles.wrapper}>
          <div className={styles.locationForm}>
            {!isVirtualRootNode &&
              _.map(inputItems, (inputItem) => {
                return (
                  <KeyValueGeneric
                    keyText={inputItem.title}
                    key={inputItem.title}
                  >
                    {inputItem.content}
                  </KeyValueGeneric>
                );
              })}

            {!_.isEmpty(infoItems) && (
              <>
                {!isVirtualRootNode &&
                  _.map(infoItems, (infoItem) => {
                    return (
                      <KeyValueGeneric
                        keyText={infoItem.title}
                        key={infoItem.title}
                      >
                        {infoItem.content}
                      </KeyValueGeneric>
                    );
                  })}
              </>
            )}
          </div>

          {!_.isEmpty(propertyItems) && (
            <div className={styles.locationForm}>
              {!isVirtualRootNode &&
                !isLoadingNodePropertyValues &&
                _.map(propertyItems, (infoItem) => {
                  return (
                    <KeyValueGeneric
                      keyText={infoItem.title}
                      key={infoItem.title}
                    >
                      {infoItem.content}
                    </KeyValueGeneric>
                  );
                })}
            </div>
          )}
        </div>
      </form>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: dimensions.standardMargin
        }}
      >
        {!isRootNode && (
          <GreyButton onClick={onDeleteNode}>
            <Icons.Delete /> {T.admin.deletelocation.title}
          </GreyButton>
        )}
        {!isRootNode && (
          <SaveButton
            disabled={saveButtonDisabled}
            onClick={onSaveNode}
            type="submit"
          >
            {nodeIsBuilding(selectedLocation)
              ? T.admin.editbuilding.savelocation
              : T.admin.editsite.savelocation}
          </SaveButton>
        )}
      </div>
      <ActionModal
        title={T.admin.editlocation.editmapcoordinate.title}
        disableCancel
        actionText={T.common.done}
        headerIcon={Icons.Edit}
        onModalClose={hideMap}
        onConfirmClick={hideMap}
        isOpen={isShowingMap}
        large
        wide
      >
        <div className={styles.mapArea}>
          <div className={styles.map}>
            <DraggableMarkerMap
              disableMarker={isRootNode}
              initialLatitude={center.lat}
              initialLongitude={center.lng}
              onCoordinateChanged={onCoordinateChanged}
            />
          </div>
        </div>
      </ActionModal>
    </>
  );
};

export default LocationForm;
